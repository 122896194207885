.how-it-works {
  display: flex;
  flex-direction: column;
  justify-content: center; // Vertically center the content
  align-items: center; // Horizontally center the content
  padding: 4em 0;
  background: #e9ecef;
  text-align: center;

  h2 {
    margin-bottom: 2em; // Maintain the margin-bottom for the h2 element
  }
}

.how-it-works-panel {
  display: flex;
  align-items: center; // This will vertically center the children
  justify-content: space-around; // This spaces out the children evenly

  .text-panel,
  .diagram-panel {
    flex: 1 1 auto; // This allows the panels to grow and shrink as needed
    margin: 0 1em; // Adds space before the text panel and after the diagram panel
  }

  .text-panel {
    // Limit the width of the text panel if necessary
    max-width: 600px; // Adjust the max-width as needed for your design
  }

  .diagram-panel {
    // Limit the width of the diagram panel if necessary
    max-width: 1200px; // Adjust the max-width as needed for your design
    img {
      width: 100%;
      height: auto;
    }
  }

  .statement {
    margin-bottom: 2em;
    
    p {
      font-size: 1rem;
      line-height: 1.6; 
    }
  }
}

@media (max-width: 768px) {
  .how-it-works {
    flex-direction: column;
    align-items: center; // Center align the items on smaller screens

    .text-panel,
    .diagram-panel {
      margin: 0; // Removes margin for smaller screens
      max-width: 100%; // Allow each panel to take full width
    }

    .diagram-panel {
      order: 2; // Ensures the diagram panel goes below the text panel
    }
  }
}

.key-functionality {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4em 0;
  background: #f8f9fa;
  text-align: center;

  h2 {
    margin-bottom: 1em;
  }
}

.key-functionality-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); // Creates a responsive grid layout
  gap: 1.5em;
  justify-content: center; // Centers the items in the grid when they don't fill the entire row
  width: 100%;
  max-width: 1350px; // Controls the max width of the grid
  margin: 0 auto; // Centers the grid within its container

  padding: 8px 15px!important;

  .key-functionality-item {
    background: #ffffff;
    padding: 2em;
    border-radius: 0.5em;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }

    h3 {
      font-size: 1.25rem;
      margin-bottom: 0.5em;
      color: white;
    }

    p {
      font-size: 1rem;
      color: white;
    }

    // Specific background colors for each item
    &:nth-child(1) {
      background: #4472C4; // Replace with the specific shade of blue you want
    }
    &:nth-child(2) {
      background: #ED7D31; // Replace with the specific shade of orange you want
    }
    &:nth-child(3) {
      background: #44546A; // This is an example of blue-gray
    }
    &:nth-child(4) {
      background: #FFC000; // Replace with the specific shade of yellow you want
    }
    &:nth-child(5) {
      background: #70AD47; // Replace with the specific shade of green you want
    }   
    &:nth-child(6) {
      background: #FF6D6D;
    } 
  }
}

// hero.module.scss
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../../../../public/img/bg-hero.png');
  background-size: cover;
  //background-color: gold;
  min-height: 600px;
  color: white;

  .hero-content {
    text-align: center;

    h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
      color: black;
    }

    p {
      font-size: 1.5rem;
      margin-bottom: 2rem;
      color: black;
    }

    .hero-cta {
      padding: 1rem 2rem;
      font-size: 1.25rem;
      color: #fff;
      background-color: #007bff;
      border: none;
      border-radius: 0.3rem;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

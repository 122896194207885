// GetInTouch.scss
.get-in-touch {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;


    h2 {
      text-align: center;
    }
  
    .form-group {
      margin-bottom: 1rem;
  
      label {
        display: block;
        margin-bottom: 0.5rem;
        color: #666;
      }
  
      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 1rem;
        color: #333;
        
        &:focus {
          border-color: #007bff;
          outline: none;
          box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
        }
      }
  
      textarea {
        resize: vertical;
        min-height: 150px;
      }
    }
  
    button[type="submit"] {
      display: block;
      width: 100%;
      padding: 0.75rem;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      color: #fff;
      background-color: #007bff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;
  
      &:hover {
        background-color: #0056b3;
      }
  
      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
      }
    }
  }
  
// Footer.scss
.footer {
    background-color: #3f3f3e;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    font-size: 0.875rem;
    height: 70px;
  
    .footer-links {
      a {
        color: #fff;
        text-decoration: none;
        margin-right: 0.5rem;
  
        &:hover {
          text-decoration: underline;
        }
  
        &:not(:last-of-type):after {
          content: "|";
          margin-left: 0.5rem;
        }
      }
    }
  
    .footer-rights {
      text-align: right;
    }
  }
  
// header.module.scss
.header {
    display: flex; // Use flexbox
    align-items: center; // Align items vertically in the center
    justify-content: space-between; // Space between logo and text
    
    padding: 8px 15px!important;

    .logo {

        padding-left: 50px;
        img {
            height: auto;
        }
    }

    .nav {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            gap: 20px; // Adjust the gap between menu items

            li {
                a {
                    text-decoration: none;
                    color: #000;
                    text-transform: uppercase;
                    
                    &:hover {
                        text-decoration: underline;
                        color: #ED7D31;
                    }
                }

                &:not(:last-child) {
                    margin-right: 20px; // Add space after each item except the last one
                }
            }
        }
    }

}
.product-suite {
  display: flex;
  flex-direction: column;
  justify-content: center; // Vertically center the content
  align-items: center; // Horizontally center the content
  padding: 4em 0;
  background: #e9ecef;
  text-align: center;

  h2 {
    margin-bottom: 2em; // Maintain the margin-bottom for the h2 element
  }
}

.product-suite-panel {
  display: flex;
  align-items: center; // This will vertically center the children
  justify-content: space-around; // This spaces out the children evenly

  .text-panel,
  .diagram-panel {
    flex: 1 1 auto; // This allows the panels to grow and shrink as needed
    margin: 0 1em; // Adds space before the text panel and after the diagram panel
  }

  .text-panel {
    // Limit the width of the text panel if necessary
    flex: 0 0 50%;

    max-width: 800px; // Adjust the max-width as needed for your design
    padding: 20px;
  }

  .print-screen-panel {
    // Limit the width of the diagram panel if necessary
    flex: 0 0 50%;

    border-radius: 5px; /* Adjust as needed for the desired roundness */
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); /* Adjust as needed for the desired shadow effect */

    max-width: 800px; // Adjust the max-width as needed for your design
    img {
      width: 100%;
      height: auto;
    }
  }
}
